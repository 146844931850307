/* @import "./assets/scss/custom/pages/_authentication.scss"; */



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

/* SCROLL TRACK */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* SCROLL THUMB */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

/* SCROLL THUMB HOVER */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}