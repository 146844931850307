.logout-checkmark {
  margin: 0 auto;
  padding: 20px 0;
  width: 100px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.custom-spinner {
  width: 60px;
  height: 60px;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgb(143, 188, 143);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 0 4px 10px rgba(40, 167, 69, 0.3);
}

/* SPIN ANIMATION */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.stepper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepper2 {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  z-index: 2;
}

.step-name {
  font-size: 14px;
}

.active .step-number {
  background-color: #38414a;
  color: #fff;
}

.complete .step-number {
  background-color: #38414a;
  color: #fff;
}

.progress-bar-stepper {
  position: absolute;
  top: 25%;
  left: 0;
  height: 4px;
  background-color: #ccc;
  width: 100%;
}

.progress-stepper {
  height: 100%;
  /* background-color: #38414a; */
  transition: 0.5s ease;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

/* Custom Scrollbar Styles for Inbound View */
.inbound-view-scroll-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.inbound-view-scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.inbound-view-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.inbound-view-scroll-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* More specific selector targeting Material React Table */

/* Customizing the scrollbar */
.inbound-view-scroll-container .MuiTable-root::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

